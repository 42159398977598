import React from "react";
import "./amusia.css";
import PrivacyPolicy from "./privacy-policy.tsx";
import SupportForm from "../../components/support-form.tsx";
import { useParams } from "react-router-dom";

export default function Amusia() {
  const params = useParams();

  React.useEffect(() => {
    document.getElementById(params?.anchor || "")?.scrollIntoView(true);
  }, [params.anchor]);

  return (
    <div className="page-amusia">
      <div className="page-content">
        <div className="page-headline">
          <span className="page-headline-logo-container">
            <img
              src="/amusia-logo.png"
              className="page-headline-logo"
              alt="logo"
            />
            <h1>amusia</h1>
          </span>
          <a
            className="page-headline-app-store-link"
            href="https://apps.apple.com/se/app/amusia/id6449686002"
          >
            <img
              className="page-headline-app-store-image default"
              alt="app store"
            />
            <img
              className="page-headline-app-store-image hover"
              alt="app store"
            />
          </a>
        </div>
        <section className="content-row title">
          <h1 className="hero-title">
            Say goodbye to off-key
            <br />
            karaoke nights.
          </h1>
        </section>
        <section className="content-row">
          <div className="content-column">
            <h1 className="">
              <span className="text-gradient-aurora">
                Completely tone deaf? Don't fret.
                <br />
                <br />
              </span>
              With amusia, you'll get back in tune in no time.
              <br />
              Built from the ground up to work seamlessly with all your Apple
              devices. Weather you're on the go, or at home - your favorite
              tuner is only a button press away.
            </h1>
          </div>
          <div className="content-column">
            <div className="watch-promo-container">
              <div className="watch-video">
                <video autoPlay loop muted playsInline>
                  <source src="/apple-watch-promo.mp4" type="video/mp4" />
                </video>
              </div>
              <img
                src="/apple-watch-frame.png"
                className="watch-frame"
                alt="watch"
              />
            </div>
          </div>
        </section>
        <section className="content-row">
          <div className="content-column">
            <div className="phone-promo-container">
              <div className="phone-video">
                <video autoPlay loop muted playsInline>
                  <source src="/apple-phone-promo.mp4" type="video/mp4" />
                </video>
              </div>
              <img
                src="/apple-phone-frame.png"
                className="phone-frame"
                alt="phone"
              />
            </div>
          </div>
          <div className="content-column">
            <h1 className="">
              Your synaesthetic friend that guides you in the tuning process
              with the help of colors and motion - mapping each key on the
              circle of fifths to aesthetically pleasing color gradients.
              <br />
              <br />
              <span className="text-gradient-sunset">
                Available now on iOS, watchOS and macOS.
              </span>
            </h1>
          </div>
        </section>
      </div>
      <div className="page-sheet">
        <div className="sheet-feature">
          <FeatureSheetIcon name="tuningfork" />
          <div className="sheet-feature-title">Assistive Tuning</div>
          <div className="sheet-feature-body">
            Guides you through the tuning process, step by step.
          </div>
        </div>
        <div className="sheet-feature">
          <FeatureSheetIcon name="metronome" />
          <div className="sheet-feature-title">Metronome</div>
          <div className="sheet-feature-body">
            With the built in metronome, you'll never miss a beat again.
          </div>
        </div>
        <div className="sheet-feature">
          <FeatureSheetIcon name="waveform" />
          <div className="sheet-feature-title">Voice Coach</div>
          <div className="sheet-feature-body">
            Practice your scales, and find out what type of voice you have.
          </div>
        </div>
      </div>
      <div className="page-footer">
        <div className="footer-content">
          <PrivacyPolicy />
          <h1 id="support" className="support-title">
            <img src="/icon-mail.svg" alt="mail" />
            Have any questions?
          </h1>
          <SupportForm
            apiKey="50c5a883-580d-4ed8-90e8-113967045b9b"
            subject="[support ticket] - amusia"
          />
          <Divider />
          <div className="footer-bottom">
            <p className="copyright">
              {`Copyright © ${new Date().getFullYear()} Filip Moberg. All rights reserved.`}
            </p>
            <a href="https://visitsweden.com/">
              <span className="flag-container">
                <p>Made with love in</p>
                <img className="bottom-flag" alt="The Swedish Flag" />
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

function Divider({ top = false, bottom = false }) {
  return (
    <div className={`divider${top ? " top" : ""}${bottom ? " bottom" : ""}`} />
  );
}

function FeatureSheetIcon({ name }) {
  return (
    <div className="sheet-feature-icon">
      <img src={`/icon-${name}.svg`} alt={name} />
    </div>
  );
}
