import React from "react";
import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
  //useParams,
} from "react-router-dom";

import "./variables.css";
import "./index.css";

import App from "./app.tsx";
import Root from "./pages/root/root.tsx";
import Amusia from "./pages/amusia/amusia.tsx";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        element: <Root />,
      },
      {
        path: "amusia/",
        element: <Amusia />,
      },
      {
        path: "amusia/:anchor",
        element: <Amusia />,
      },
    ],
    //errorElement: <Error />,
  },
]);

/*
function AppValidator() {
  //const params = useParams();

  if (window.location.port !== "3000" && window.location.protocol === "http:") {
    window.location.href = window.location.href.replace("http:", "https:");
    return null;
  }

  return <App />;
}
*/
ReactDOM.createRoot(document.getElementById("root")).render(
  <RouterProvider router={router} />
);
