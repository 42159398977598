import React from "react";
//import { CSSTransition } from "react-transition-group";
import { Outlet } from "react-router-dom";
import Button from "./button.tsx";
import "./app.css";

function App() {
  /*
  const [confirmModal, setConfirmModal] =
    React.useState<React.JSX.Element | null>();

  const helpHandler = React.useCallback(() => {
    setConfirmModal(
      <ConfirmModal
        text={
          <span>
            <b>Privacy Policy</b>
            <p>-</p>
          </span>
        }
        successText="Ok"
        onExited={() => {
          setConfirmModal(null);
        }}
      />
    );
  }, []);
  */

  return (
    <div className={`app`}>
      <div
        className={`app-header button-container transition no-print app-header__fixed`}
      >
        <Button.Provider value={{}}>
          <div className="app-header--left button-container">
            <a className="logo-container no-print" href="/">
              <img alt="logo" className="logo-source" src="/icon-bird.svg" />
            </a>
            <Button secondary to="/">
              onelittlebird.io
            </Button>
            <Button secondary to="/amusia">
              amusia
            </Button>
          </div>
          <div className="app-header--center button-container"></div>
          <div className="app-header--right button-container"></div>
        </Button.Provider>
      </div>
      <div className="app-content">
        <Outlet />
      </div>
      {/*confirmModal*/}
    </div>
  );
}

/*
const noop = () => {};

function ConfirmModal({
  text,
  successText = "Ok",
  failureText = "Avbryt",
  onSuccess = noop,
  onFailure = noop,
  onExited = noop,
}) {
  const nodeRef = React.useRef(null);
  const [mounted, setMounted] = React.useState(false);

  React.useLayoutEffect(() => {
    setMounted(true);
  }, []);

  const handleOnSuccess = React.useCallback(() => {
    setMounted(false);
    setTimeout(() => {
      onSuccess?.();
    }, 600);
  }, [onSuccess]);

  const handleOnFailure = React.useCallback(() => {
    setMounted(false);
    setTimeout(() => {
      onFailure?.();
    }, 600);
  }, [onFailure]);

  return (
    <CSSTransition
      nodeRef={nodeRef}
      in={mounted}
      timeout={100}
      classNames="modal-transition"
      appear
      unmountOnExit
      onExited={onExited}
    >
      <div ref={nodeRef} className="app-modal modal-transition">
        <div className="app-modal--container">
          <div className="app-modal--container-text">{text}</div>
          <div className="button-container">
            {onFailure ? (
              <Button secondary onClick={handleOnFailure}>
                {failureText}
              </Button>
            ) : null}
            <Button onClick={handleOnSuccess}>{successText}</Button>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
}
*/
export default App;
