import React from "react";
import "./root.css";

export default function Root() {
  return (
    <div className="page-root">
      <img src="/icon-bird.svg" className="page-logo" alt="logo" />
    </div>
  );
}
