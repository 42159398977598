import React from "react";
import "./support-form.css";

type SupportFormProps = {
  apiKey: string;
  subject: string;
};

export default function SupportForm({ apiKey, subject }: SupportFormProps) {
  const [loading, setLoading] = React.useState(false);
  //const [status, setStatus] = React.useState<string | undefined>(undefined);
  const formRef = React.useRef<HTMLFormElement>(null);

  const onSubmitHandler = React.useCallback(
    (e: SubmitEvent) => {
      e.preventDefault();
      const form = formRef.current;
      if (form) {
        const formData = new FormData(form);
        const object = Object.fromEntries(formData);
        object.subject = `${subject} (#${generateId()})`;
        const json = JSON.stringify(object);
        setLoading(true);
        //setStatus(undefined);

        fetch("https://api.web3forms.com/submit", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: json,
        })
          .then(async (response) => {
            if (response.status !== 200) {
              //let json = await response.json();
              //setStatus(json.message);
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .then(function () {
            form.reset();
          })
          .finally(() => {
            setLoading(false);
          });
      }
    },
    [subject]
  );

  React.useEffect(() => {
    const form = formRef.current;
    form?.addEventListener("submit", onSubmitHandler);
    return () => form?.removeEventListener("submit", onSubmitHandler);
  }, [onSubmitHandler]);

  return (
    <div className="form-container">
      <form
        ref={formRef}
        method="POST"
        id="form"
        className={loading ? "is-loading" : ""}
      >
        <input type="hidden" name="access_key" value={apiKey} />
        <input type="hidden" name="subject" value={subject} />
        <input
          type="checkbox"
          name="botcheck"
          id=""
          style={{ display: "none" }}
        />

        <textarea
          name="message"
          required
          placeholder="Write your favorite haiku here."
          disabled={loading}
        />

        <span className="form-buttons">
          <input
            type="text"
            name="name"
            placeholder="name"
            required
            disabled={loading}
          />
          <input
            type="email"
            name="email"
            placeholder="email"
            required
            disabled={loading}
          />
          <button type="submit" disabled={loading}>
            send
          </button>
        </span>
      </form>
    </div>
  );
}

function dec2hex(dec): string {
  return dec.toString(16).padStart(2, "0");
}

export function generateId(len = 6): string {
  var arr = new Uint8Array(len / 2);
  window.crypto.getRandomValues(arr);
  return Array.from(arr, dec2hex).join("");
}
