import React from "react";
import "./amusia.css";

export default function PrivacyPolicy() {
  return (
    <div className="wpembed-content">
      <h1 id="privacy-policy">Privacy policy</h1>
      <h2>Introduction</h2>
      <p>
        Our vision is to create applications that's intuitive and easy to
        understand and use. We feel the same mindset should apply for our
        privacy policy - so that you as a user will know exactly what data we
        collect and what we do with it.
      </p>
      <p>
        We process personal data as per the General Data Protection Regulation
        (GDPR) and other relevant data protection and privacy laws applicable to
        us.
      </p>
      <h2>Collection of data</h2>
      <p>
        Your privacy is of the utmost importance to us. All data collected by us
        is done through a third party service called Aptabase.
      </p>
      <p>
        We do not collect any device specific identifier such as Device ID,
        Hostname or Hardware Identifier. Instead, Aptabase generates a unique
        user identifier on the server based on a strong hash of the client’s IP
        Address, User Agent and a strong salt - which differs for each app and
        is thrown away every 24 hours.
      </p>
      <p>
        This is a common privacy-friendly strategy that makes it impossible for
        us to correlate sessions that happens throughout different days or apps.
      </p>
      <p>
        We can only use your data to discover insights into our apps without
        identifying the user behind each action. All analytics data collected by
        us are stored in Aptabase for up to 5 years.
      </p>
      <p>
        You cannot request us to delete your data, simply because it’s
        impossible to know what data relates to you.
      </p>
      <h2>Changes</h2>
      <p>
        We may update this policy as needed to comply with relevant regulations
        and reflect any new practices.
      </p>
      <p>This document was last updated on March 21, 2024.</p>
    </div>
  );
}
